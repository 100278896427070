<template>
  <b-container fluid="true">
    <!-- Reports Interface controls -->
    <h5 class="my-3"><strong>REPORTS BY ZONE</strong></h5>
    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="multiple" allowClear placeholder="Publishers" :showSearch="true" :filterOption="true"
          optionFilterProp="children" v-model="filteredColumns.selectedPublishers.Values">
          <a-select-option v-for="obj in ORTBpublisher.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name
            }}</a-select-option>
        </a-select>
        <a-button @click="addPublishers"><i class="fa fa-user"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Banner Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.bannerzones.Values">
          <a-select-option v-for="obj in bannerzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Video Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.videozones.Values">
          <a-select-option v-for="obj in videozone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="RTB Zones" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.rtbzones.Values">
          <a-select-option v-for="obj in rtbzone.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Pop Feeds" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.popfeeds.Values">
          <a-select-option v-for="obj in popfeed.list" :key="obj.Id">[{{ obj.Id }}] {{ obj.Name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Countries" style="width: 100%" :showSearch="true"
          :filterOption="countryFilter" optionFilterProp="children" v-model="filteredColumns.selectedCountries.Values">
          <!-- <a-select-option v-for="obj in info.countries" :key="obj.CC">{{obj.Country}}</a-select-option> -->
          <a-select-option v-for="obj in info.countries" :key="obj.CC"><img :src="matchIcon(countries, obj.CC)" />
            {{ obj.Country }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Operating Systems" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedOs.Values">
          <a-select-option v-for="obj in info.oss" :key="obj.Family">{{ obj.Family }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select mode="multiple" allowClear placeholder="Browsers" style="width: 100%" :showSearch="true"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedBrowsers.Values">
          <a-select-option v-for="obj in info.browsers" :key="obj.Family">{{ obj.Family }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row  :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24">
        <a-select allowClear mode="single" placeholder="Environment" style="width: 100%" :showSearch="false"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedEnvironment.Values">
          <a-select-option v-for="env in environment" :key="env.value">{{ env.type }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select allowClear mode="single" placeholder="Ad Type" style="width: 100%" :showSearch="false"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedAdType.Values">
          <a-select-option v-for="ad in adtype" :key="ad.value">{{ ad.type }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-select allowClear mode="single" placeholder="Device Type" style="width: 100%" :showSearch="false"
          :filterOption="true" optionFilterProp="children" v-model="filteredColumns.selectedDeviceType.Values">
          <a-select-option v-for="device in devicetype" :key="device.value">{{ device.type }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="tags" allowClear placeholder="App Bundles" style="width: 100%" :showSearch="true"
          :tokenSeparators="[' ']" :filterOption="true" optionFilterProp="children"
          v-model="filteredColumns.appBundle.Values">
        </a-select>
        <a-button @click="copyAppBundles"><i class="fa fa-copy"></i></a-button>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 4]">
      <a-col :xl="6" :md="12" :xs="24" class="custom-report-input-wrapper">
        <a-select mode="tags" allowClear placeholder="Sites" style="width: 100%" :showSearch="true"
          :tokenSeparators="[' ']" :filterOption="true" optionFilterProp="children"
          v-model="filteredColumns.site.Values">
        </a-select>
        <a-button @click="copySites"><i class="fa fa-copy"></i></a-button>
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-input v-model="filteredColumns.pubId.Contains" placeholder="Pub ID" style="width: 100%" />
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <a-input v-model="filteredColumns.size.Contains" placeholder="Size" style="width: 100%" />
      </a-col>
      <a-col :xl="6" :md="12" :xs="24">
        <div style="display: flex; justify-content: space-between">
          <a-range-picker :defaultValue="this.ranges['Today']" @change="setDateRange" :ranges="ranges" :allowClear="false"
            style="width: 70%" />
          <a-button type="primary" class="ml-2" @click="setFilters" style="width: 30%">
            Apply
          </a-button>
          <b-button size="sm" class="btn-info mr-1" @click="exportToCsv" title="Export to CSV" style="margin-left: 6px">
            <i class="fa fa-file-excel-o"></i>
          </b-button>
        </div>
      </a-col>
    </a-row>
    <b-row class="mt-2">
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'ortb-reports-zone'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-reports-zone-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>

      <!--<b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>-->
    </b-row>

    <!-- Main table element -->
    <b-table @sort-changed="sortingChanged" ref="table" show-empty :small="true" :bordered="true" :responsive="true"
      sort-by="Profit" :sort-desc="true" :striped="true" :hover="true" stacked="md" :items="getTableData"
      :fields="filteredFields" :current-page="currentPage" :per-page="perPage" :no-local-sorting="true"
      @filtered="onFiltered" :busy="isBusy">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #cell(Env)="data">
        {{ formatEnv(data.item.Env) }}
      </template>
      <template #cell(AdType)="data">
        {{ formatAdType(data.item.AdType) }}
      </template>
      <template #cell(DeviceType)="data">
        {{ formatDeviceType(data.item.DeviceType) }}
      </template>
      <template #cell(Roi)="data">
        {{ Number(data.item.Roi).toFixed(2) }}%
      </template>
      <template #cell(RtbzoneId)="data">
        <router-link v-if="data.item.RtbzoneId && data.item.RtbzoneId !== 'N/A'"
          :to="{ name: 'rtbzone-update', params: { id: data.item.RtbzoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.RtbzoneId }}
        </router-link>
        <div v-else>
          {{ data.item.RtbzoneId }}
        </div>
      </template>
      <template #cell(RtbzoneName)="data">
        <router-link v-if="data.item.RtbzoneId && data.item.RtbzoneId !== 'N/A'"
          :to="{ name: 'rtbzone-update', params: { id: data.item.RtbzoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.RtbzoneName }}
        </router-link>
        <div v-else>{{ data.item.RtbzoneName }}</div>
      </template>

      <template #cell(BannerzoneId)="data">
        <router-link v-if="data.item.BannerzoneId && data.item.BannerzoneId !== 'N/A'"
          :to="{ name: 'bannerzone-update', params: { id: data.item.BannerzoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.BannerzoneId }}
        </router-link>
        <div v-else>
          {{ data.item.BannerzoneId }}
        </div>
      </template>
      <template #cell(BannerzoneName)="data">
        <router-link v-if="data.item.BannerzoneId && data.item.BannerzoneId !== 'N/A'"
          :to="{ name: 'bannerzone-update', params: { id: data.item.BannerzoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.BannerzoneName }}
        </router-link>
        <div v-else>{{ data.item.BannerzoneName }}</div>
      </template>
      <template #cell(PopfeedId)="data">
        <router-link v-if="data.item.PopfeedId && data.item.PopfeedId !== 'N/A'"
          :to="{ name: 'popfeed-update', params: { id: data.item.PopfeedId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.PopfeedId }}
        </router-link>
        <div v-else>
          {{ data.item.PopfeedId }}
        </div>
      </template>
      <template #cell(PopfeedName)="data">
        <router-link v-if="data.item.PopfeedId && data.item.PopfeedId !== 'N/A'"
          :to="{ name: 'popfeed-update', params: { id: data.item.PopfeedId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.PopfeedName }}
        </router-link>
        <div v-else>{{ data.item.PopfeedName }}</div>
      </template>
      <template #cell(VideozoneId)="data">
        <router-link v-if="data.item.VideozoneId && data.item.VideozoneId !== 'N/A'"
          :to="{ name: 'videozone-update', params: { id: data.item.VideozoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.VideozoneId }}
        </router-link>
        <div v-else>
          {{ data.item.VideozoneId }}
        </div>
      </template>
      <template #cell(VideozoneName)="data">
        <router-link v-if="data.item.VideozoneId && data.item.VideozoneId !== 'N/A'"
          :to="{ name: 'videozone-update', params: { id: data.item.VideozoneId } }" target="_blank"
          class="table-cursor-pointer">
          {{ data.item.VideozoneName }}
        </router-link>
        <div v-else>{{ data.item.VideozoneName }}</div>
      </template>

      <template #cell(CC)="data">
        <div><img :src="matchIcon(countries, data.item.CC)" /> {{ data.item.CC }}</div>
      </template>
      <template #cell(FilterRate)="data">
        <div class="background-indicator"
          :class="{ 'background-indicator-red': data.item.FilterRate > 70, 'background-indicator-orange': data.item.FilterRate > 40 && data.item.FilterRate <= 70, 'background-indicator-yellow': data.item.FilterRate > 10 && data.item.FilterRate <= 40 }">
          {{ parseFloat(data.item.FilterRate).toFixed(2) }}%
        </div>
      </template>
      <template #cell(Coverage)="data">
        <div v-if="data.item.Coverage">{{ (Math.round(data.item.Coverage * 100) / 100).toFixed(2) }}%</div>
      </template>
      <template v-if="!loading" #custom-foot>
        <b-tr>
          <b-td v-for="col in filteredFields" :key="col.key">
            <div v-if="col.summed">
              <p v-if="col.formatter === '$formatNumber'" class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(sum[col.key]) }}
              </p>
              <p v-else class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', {
      style: 'currency', currency: 'USD', maximumFractionDigits: 2
    }).format(sum[col.key]) }}
              </p>
            </div>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
      (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) +
        perPage).toLocaleString()
    }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getData, exportData } from '../../../api/display/reports_zone'
import { mapState, mapGetters } from 'vuex'
import zoneColumns from './columns/zoneFields'
import moment from 'moment'
import staticCountryData from '@/resources/countries.json'
import Vue from 'vue'

export default {
  name: 'reports',
  components: { columnSelect, perPageSelect },
  data() {
    return {
      countries: staticCountryData,
      sum: {},
      sortDirection: 'desc',
      userId: this.$store.state.auth.id,
      environment: [
        { type: 'All', value: 0 },
        { type: 'Web', value: 1 },
        { type: 'App', value: 2 },
      ],
      adtype: [
        { type: 'All', value: 0 },
        { type: 'Banner', value: 1 },
        { type: 'Video', value: 2 },
        { type: 'Audio', value: 3 },
        { type: 'Native', value: 4 },
        { type: 'Pop', value: 5 },
      ],
      devicetype: [
        { type: 'All', value: 0 },
        { type: 'Desktop', value: 1 },
        { type: 'Mobile', value: 2 },
        { type: 'Tablet', value: 3 },
        { type: 'CTV', value: 4 },
        { type: 'Other', value: 5 },
      ],
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'This Week': [moment().startOf('isoWeek'), moment()],
        'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'),
          moment().subtract(1, 'week').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
      },
      dateRange: {
        Column: 'DateStats',
        From: '',
        To: '',
        Values: [],
      },
      filteredColumns: {
        selectedPublishers: {
          Column: 'PublisherId',
          Values: [],
        },
        bannerzones: {
          Column: 'BannerzoneId',
          Values: [],
        },
        videozones: {
          Column: 'VideozoneId',
          Values: [],
        },
        rtbzones: {
          Column: 'RtbzoneId',
          Values: [],
        },
        popfeeds: {
          Column: 'PopfeedId',
          Values: [],
        },
        selectedCountries: {
          Column: 'CC',
          Values: [],
        },
        selectedOs: {
          Column: 'OsFamily',
          Values: [],
        },
        selectedBrowsers: {
          Column: 'BrowserFamily',
          Values: [],
        },
        selectedEnvironment: {
          Column: 'Env',
          Values: [],
        },
        selectedAdType: {
          Column: 'AdType',
          Values: [],
        },
        selectedDeviceType: {
          Column: 'DeviceType',
          Values: [],
        },
        appBundle: {
          Column: 'AppBundle',
          Values: [],
        },
        site: {
          Column: 'Site',
          Values: [],
        },
        pubId: {
          Column: 'PubId',
          Contains: '',
        },
        size: {
          Column: 'Size',
          Contains: '',
        },
      },
      loading: false,
      isBusy: false,
      tableData: [],
      fields: zoneColumns,
      filters: [],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      order: [{ Column: 'Profit', Ord: 'DESC' }],
      total: 0,
    }
  },
  computed: {
    ...mapState(['ORTBpublisher', 'info', 'bannerzone', 'videozone', 'rtbzone', 'auth', 'popfeed']),
    ...mapGetters('ORTBpublisher', ['getActivePublishers']),
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.total
    },
    appBundleString() {
      return this.filteredColumns.appBundle.Values.join('\n')
    },
    sitesString() {
      return this.filteredColumns.site.Values.join('\n')
    },
  },
  methods: {
    editRTBZone(id, remote) {
      if (!id) {
      } else if (remote) {
        return `/remotertbzone/update/${id}`
      } else {
        const path = remote ? `/remotertbzone/update/${id}` : `/rtbzone/update/${id}`
        return path
      }
    },
    addPublishers() {
      this.filteredColumns.selectedPublishers.Values = []
      this.getActivePublishers.forEach(el => {
        if (el.AccManagerId === this.userId) {
          this.filteredColumns.selectedPublishers.Values.push(el.Id)
        }
      })
    },
    exportToCsv() {
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      exportData(columns, this.filters, this.order, this.currentPage, this.perPage).then(response => {
        const blob = new Blob([response], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'reports_by_zone_' + moment().format('YYYY-MM-DD') + '.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    countryFilter(inputValue, option) {
      return option.componentOptions.children[1].text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    copyAppBundles() {
      navigator.clipboard.writeText(this.appBundleString)
      Vue.prototype.$notification.success({
        message: 'App Bundles copied to clipboard.',
      })
    },
    copySites() {
      navigator.clipboard.writeText(this.sitesString)
      Vue.prototype.$notification.success({
        message: 'Sites copied to clipboard.',
      })
    },
    setFilters() {
      const array = []
      for (const col in this.filteredColumns) {
        if (this.filteredColumns[col].Values && this.filteredColumns[col].Values.length !== 0) {
          array.push(this.filteredColumns[col])
        } else if (this.filteredColumns[col].Contains && this.filteredColumns[col].Contains !== '') {
          array.push(this.filteredColumns[col])
        }
      }
      if (this.dateRange.From !== '' && this.dateRange.To !== '') {
        array.push(this.dateRange)
      }
      this.filters = array
      this.$refs.table.refresh()
    },
    setDateRange(date) {
      this.filters = this.filters.filter(ele => ele.Column !== 'Date')
      this.dateRange.From = date[0] ? date[0].format('YYYY-MM-DD') : ''
      this.dateRange.To = date[1] ? date[1].format('YYYY-MM-DD') : ''
      this.filters.push(this.dateRange)
    },
    sortingChanged(ctx) {
      this.order = []
      this.order.push({
        Column: ctx.sortBy,
        Ord: ctx.sortDesc ? 'DESC' : 'ASC',
      })
      // this.$refs.table.refresh()
    },
    getTableData(ctx, callback) {
      this.loading = true
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      getData(this.currentPage, this.perPage, columns, this.filters, this.order).then(response => {
        this.tableData = response.Data
        this.sum = response.Sum
        this.total = response.Total
        this.loading = false
        callback(response.Data)
      })
      return null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatEnv(val) {
      if (val === '0') {
        return 'All'
      } else if (val === '1') {
        return 'Web'
      } else if (val === '2') {
        return 'App'
      }
    },
    formatAdType(val) {
      if (val === '0') {
        return 'All'
      } else if (val === '1') {
        return 'Banner'
      } else if (val === '2') {
        return 'Video'
      } else if (val === '3') {
        return 'Audio'
      } else if (val === '4') {
        return 'Native'
      } else if (val === '5') {
        return 'Pop'
      }
    },
    formatDeviceType(val) {
      if (val === '0') {
        return 'All'
      } else if (val === '1') {
        return 'Desktop'
      } else if (val === '2') {
        return 'Mobile'
      } else if (val === '3') {
        return 'Tablet'
      } else if (val === '4') {
        return 'CTV'
      } else if (val === '5') {
        return 'Other'
      }
    },
    callback(value) {
      return value
    },
  },
  created() {
    this.$store.dispatch('bannerzone/LOAD_BANNERZONES_SHORT')
    this.$store.dispatch('videozone/LOAD_VIDEOZONES_SHORT')
    this.$store.dispatch('rtbzone/LOAD_RTBZONES_SHORT')
    this.$store.dispatch('popfeed/LOAD_FEEDS_SHORT')
    this.$store.dispatch('ORTBpublisher/LOAD_PUBLISHERS_SHORT')
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.setDateRange(this.ranges.Today)
  },
  watch: {
    fields: {
      handler(val) {
        this.$refs.table.refresh()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.custom-report-input-wrapper {
  display: flex;
  justify-content: center;
}

.custom-report-input-wrapper>.ant-select {
  width: 100%;
  margin-right: 2px;
}

/* style="width: 32px; height: 32px; display: flex; justify-content: center; align-items: center;" */
.custom-report-input-wrapper>.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}
</style>
